import { UtilityCategoryDto } from '@api/models/SiteUtilityDailyConsumptionDto';
import { LineBarChartType } from '@components/charts/Chart.types';
import { TimeRange } from '@components/time-range-select/TimeRangeSelect';
import { LocalisationType } from '@i18n/localisation';
import { convertToCSV } from '@utils/ExportUtils';
import { Chart, ChartTypeRegistry } from 'chart.js';
import dayjs, { Dayjs } from 'dayjs';
import { TFunction } from 'i18next';
import { chain, orderBy, round, sumBy } from 'lodash';
import { lighten, transparentize } from 'polished';
import { DefaultTheme } from 'styled-components';

export type TChart = Chart<keyof ChartTypeRegistry, TXYDateChartDataPoint[], string>;

export enum CustomUtilityCategory {
  DailyTotal = 'DailyTotal',
  Target = 'Target',
  Unmetered = 'Unmetered'
}

export enum ConsumptionChartStack {
  ThisYear = 'ThisYear',
  LastYear = 'LastYear',
  Target = 'Target'
}

export type TXYDateChartDataPoint = {
  x: string;
  y: number;
  date: Dayjs;
}

export type ConsumptionChartDataset = {
  id: string;
  category: string;
  label: string;
  data: TXYDateChartDataPoint[];
  dataUnit: string;
  type: LineBarChartType;
  stack: ConsumptionChartStack;
  legend: {
    color: string,
    group: ConsumptionChartStack,
    order: number
  },
  isGasWidget?: boolean;
}
export type TConversion = {
  unit: string;
  modifier?: (x: number) => number;
}

export const getChartType = (category: string) => {
  switch (category) {
    case CustomUtilityCategory.Target:
      return LineBarChartType.Line;
    case CustomUtilityCategory.DailyTotal:
      return LineBarChartType.Bar;
    default:
      return LineBarChartType.Bar;
  }
}

// Accumulate the dataPoints by summing each value with the value at the previous index position 
export const accumulateDataPoints = (dataPoints: TXYDateChartDataPoint[]): TXYDateChartDataPoint[] => {
  let sum = 0;
  return dataPoints.map(dataPoint => ({
    ...dataPoint,
    y: sum = (sum || 0) + (dataPoint.y ?? NaN)
  }));
}

// Aggregate daily to monthly data points
export const aggregateMonthlyData = (dailyData: TXYDateChartDataPoint[]): TXYDateChartDataPoint[] =>
  chain(dailyData)
    .groupBy((item) => dayjs(item.x).format('YYYY-MM')) // Group by month (YYYY-MM)
    .map((dataPoints, month) => ({
      x: month,
      y: round(sumBy(dataPoints, x => isNaN(x.y) ? 0 : x.y), 2),
      date: dataPoints[0].date
    }))
    .value();

// Format the value by applying a modifer and optional conversion function to the value
export const formatValue = (value: number | null, conversion: TConversion): number => {
  if (value === null) {
    return NaN;
  }

  return round(conversion.modifier ? conversion.modifier(value) : value, 2);
};

// Get consumption value for a specific day/date
export const getConsumptionByDate = (date: Dayjs, category: UtilityCategoryDto, conversion: TConversion): number => {
  const day = category.days.find(x => date.isSame(x.day, 'day'));
  return day ? formatValue(day.consumption, conversion) : NaN;
};

// Generate daily data points for chart
export const generateDataPoints = (labels: string[], category: UtilityCategoryDto, conversion: TConversion, aggregateData: boolean, yearOffset = 0): TXYDateChartDataPoint[] => {
  let dataPoints = labels.map(x => {
    const date = dayjs(x);
    const offsetDate = dayjs(x).add(yearOffset, 'year');

    return {
      x: date.format('YYYY-MM-DD'),
      y: getConsumptionByDate(offsetDate, category, conversion),
      date: offsetDate
    };
  });

  if (aggregateData) {
    dataPoints = aggregateMonthlyData(dataPoints);
  }

  return dataPoints;
};

// Generate daily labels for chart x-axis
export const generateLabels = (range: TimeRange) => {
  const dateArray: string[] = [];
  let current = dayjs(range.from);

  while (current <= range.to) {
    dateArray.push(current.format('YYYY-MM-DD'));
    current = current.add(1, 'day');
  }

  return dateArray;
}

const getDatasetColor = (category: string, stack: ConsumptionChartStack, theme: DefaultTheme, index: number) => {
  if (stack === ConsumptionChartStack.LastYear) {
    return category === CustomUtilityCategory.DailyTotal ? theme.palette.secondary : lighten(0.12 * index, theme.palette.secondary);
  } else if (category === CustomUtilityCategory.Target) {
    return theme.action.active;
  } else if (category === CustomUtilityCategory.DailyTotal) {
    return theme.palette.primary;
  }

  return lighten(0.12 * index, theme.palette.primary);
};

export const getDatasetProperties = (category: string, type: LineBarChartType, theme: DefaultTheme, stack: ConsumptionChartStack, index: number) => {
  const color = getDatasetColor(category, stack, theme, index);

  if (type === LineBarChartType.Line) {
    return {
      legend: {
        color: color,
        group: stack,
        order: 2
      },
      borderColor: color,
      pointBorderColor: transparentize(0.8, color),
      pointBackgroundColor: color
    };
  }

  return {
    borderColor: theme.palette.text.onPrimary,
    borderWidth: { top: 1 },
    backgroundColor: color,
    hoverBackgroundColor: color,
    legend: {
      color: color,
      group: stack,
      order: 1
    },
  };
};


// Generate CSV string from datasets
export const generateCsv = (datasets: ConsumptionChartDataset[], t: TFunction, localisation: LocalisationType, unit: string): string => {
  const data = orderBy(datasets.flatMap(dataset => dataset.data.map(dataPoint => ({
    category: dataset.label,
    date: dataPoint.date,
    consumption: dataPoint.y
  }))), x => x.date);

  const csv = convertToCSV([
    {
      key: 'category',
      header: t('Category', { ns: 'common' })
    },
    {
      key: 'date',
      header: t('Date', { ns: 'common' }),
      modifier: x => x.date.format(localisation.dateFormats.date)
    },
    {
      key: 'consumption',
      header: `${t('Consumption', { ns: 'common' })} (${unit})`,
      modifier: x => isNaN(x.consumption) ? '' : `${x.consumption}`
    },
  ], data);

  return csv;
};

// Sort utilities with Target and DailyTotal datatsets at the end (so that colors are applied correctly to the other categories)
export const sortUtilities = (categories: UtilityCategoryDto[]) => orderBy(categories,
  [(x) => ((x.category === CustomUtilityCategory.Target || x.category === CustomUtilityCategory.DailyTotal) ? 'ZZZ' : x.category)], ['asc']);