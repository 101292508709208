import { convertToCSV, exportCSV } from '@utils/ExportUtils';
import { GetMetricValue } from '@utils/MetricUtils';
import { DeviceModel } from '@api/enums/DeviceModel';
import { MetricType } from '@api/enums/MetricType';
import { DeviceDetails } from '@api/models/DeviceDetails';
import { HierarchySpace } from '@api/models/Hierarchy';
import { Metric } from '@api/models/Metric';
import { useDeviceConfigContext } from '@contexts/DeviceConfigContext/DeviceConfigContext';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { useTenantContext } from '@contexts/TenantContext/TenantContext';
import { includesCI } from '@utils/StringUtils';
import { useSiteContext } from '@pages/site/SiteProvider';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useDeviceManagement = () => {
  const { t } = useTranslation();
  const { tenant } = useTenantContext();
  const { site } = useSiteContext();
  const { getDeviceType, getDeviceConfig } = useDeviceConfigContext();
  const { localisation } = useLocalisation();
  const [tableRecordCount, setTableRecordCount] = useState<number>();
  const [spaceFilter, setSpaceFilter] = useState<HierarchySpace[] | undefined>([]);
  const [modelFilter, setModelFilter] = useState<string[]>([]);
  const [typeFilter, setTypeFilter] = useState<string[]>([]);
  const [searchString, setSearchString] = useState<string>('');

  const searchFilter = useCallback((x: DeviceDetails) => {
    return includesCI(x.friendlyName, searchString)
      || includesCI(x.deviceModel, searchString)
      || includesCI(x.deviceIdentifier, searchString)
      || includesCI(x.spaceName, searchString)
  }, [searchString]);

  const locationFilter = useCallback((x: DeviceDetails) => {
    return (spaceFilter && x.spaceId) ? spaceFilter.map(x => x.id).includes(x.spaceId) : true;
  }, [spaceFilter]);

  const deviceModelFilter = useCallback((x: DeviceDetails) => {
    return modelFilter.length > 0 ? modelFilter.includes(x.deviceModel) : true;
  }, [modelFilter]);

  const deviceTypeFilter = useCallback((x: DeviceDetails) => {
    return typeFilter.length > 0 ? typeFilter.includes(getDeviceType(x.deviceModel)) : true;
  }, [typeFilter, getDeviceType]);

  const getLastReading = useCallback((deviceModel: DeviceModel, readings: Metric[]) => {
    let message = '-';
    let suffix = '';

    const deviceConfig = getDeviceConfig(deviceModel);

    let lastReading = readings.find(x => x.metricType === MetricType.HeatingKwh
      || x.metricType === MetricType.ElectricityKwh
      || x.metricType === MetricType.WaterVolume
      || x.metricType === MetricType.CoolingKwh
      || x.metricType === MetricType.GasVolume);

    if (deviceConfig?.isSmartMeter) {
      lastReading = readings.find(x => x.metricType === MetricType.ElectricityKwhDelta
        || x.metricType === MetricType.GasVolumeDelta
        || x.metricType === MetricType.ElectricityExportKwhDelta);
    }

    if (lastReading !== undefined) {
      message = lastReading.value;
      suffix = lastReading.metricType === MetricType.WaterVolume ||
        lastReading.metricType === MetricType.WaterVolumeDelta ||
        lastReading.metricType === MetricType.GasVolume ||
        lastReading.metricType === MetricType.GasVolumeDelta ? 'm³' : 'kWh';
    }

    return {
      Message: message,
      MetricTypeSuffix: suffix
    };
  }, [getDeviceConfig]);

  const handleExportCsv = (devices: DeviceDetails[], buildingName?: string) => {
    // Example file name: Utopi_Eastworks_28 Jun 2024_All Devices.csv 
    const building = buildingName ? buildingName + '_' : '';
    const fileName = `${tenant.name}_${site.name}_${building}${(dayjs(new Date()).format(localisation.dateFormats.date))}_${t('DeviceManagement.AllDevices', { ns: 'settingsDevice' })}`;

    const csv = convertToCSV([
      {
        key: 'friendlyName',
        header: t('DeviceManagement.FriendlyName', { ns: 'settingsDevice' })
      },
      {
        key: 'manufacturer',
        header: t('DeviceManagement.Manufacturer', { ns: 'settingsDevice' }),
      },
      {
        key: 'deviceModel',
        header: t('DeviceManagement.DeviceModel', { ns: 'settingsDevice' })
      },
      {
        key: 'deviceIdentifier',
        header: t('DeviceManagement.DeviceIdentifier', { ns: 'settingsDevice' })
      },
      {
        key: 'spaceName',
        header: t('DeviceManagement.Space', { ns: 'settingsDevice' })
      },
      {
        key: 'lastMeasuredOn',
        header: t('DeviceManagement.LastMeasuredOn', { ns: 'settingsDevice' }),
        modifier: device => device.lastMeasuredOn ? format(new Date(device.lastMeasuredOn), localisation.dateFormats.default) : 'Never',
      },
      {
        key: 'metrics',
        header: t('DeviceManagement.LastReading', { ns: 'settingsDevice' }),
        modifier: device => `${getLastReading(device.deviceModel, device.metrics).Message}${getLastReading(device.deviceModel, device.metrics).MetricTypeSuffix}`
      },
      {
        key: 'power',
        header: t('DeviceManagement.Power', { ns: 'settingsDevice' }),
        modifier: device => {
          const value = device.metrics ? GetMetricValue({ metrics: device.metrics }, MetricType.BatteryVoltage) : 0;
          return value ? `${parseFloat(value).toFixed(2)}v` : '-';
        }
      },
      {
        key: 'signal',
        header: t('DeviceManagement.Signal', { ns: 'settingsDevice' }),
        modifier: device => {
          const value = GetMetricValue(device, MetricType.SignalStrength);
          return value ? `${parseFloat(value).toFixed(0)}%` : '-'
        }
      }
    ], devices);

    exportCSV(fileName, csv);
  };

  return {
    getLastReading,
    locationFilter,
    deviceModelFilter,
    deviceTypeFilter,
    searchFilter,
    spaceFilter,
    setSpaceFilter,
    modelFilter,
    setModelFilter,
    typeFilter,
    setTypeFilter,
    searchString,
    setSearchString,
    tableRecordCount,
    setTableRecordCount,
    handleExportCsv
  };
};