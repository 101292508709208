import DiagnosticGraphWidget from './diagnostics-widgets/DiagnosticGraphWidget';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { DeviceWithMetrics } from '@api/models/DeviceWithMetrics';
import { MetricType } from '@api/enums/MetricType';
import { Metric } from '@api/models/Metric';
import { round, stringToFloat } from '@utils/NumberUtils';
import { createMetricObject } from '@utils/ObjectUtils';

interface IDiagnostics {
  key: number;
  name: string;
  L1?: number;
  L2?: number;
  L3?: number;
  N?: number;
  Total?: number;
}

type PropTypes = {
  device: DeviceWithMetrics;
};

class RequiredMetrics {
  [MetricType.ElectricityGeneratedKwh]: Metric;
  [MetricType.ElectricityKwh]: Metric;
  [MetricType.ElectricityL1Voltage]: Metric;
  [MetricType.ElectricityL2Voltage]: Metric;
  [MetricType.ElectricityL3Voltage]: Metric;
  [MetricType.ElectricityL1Current]: Metric;
  [MetricType.ElectricityL2Current]: Metric;
  [MetricType.ElectricityL3Current]: Metric;
  [MetricType.ElectricityL1PowerFactor]: Metric;
  [MetricType.ElectricityL2PowerFactor]: Metric;
  [MetricType.ElectricityL3PowerFactor]: Metric;
  [MetricType.ElectricityL1ActivePower]: Metric;
  [MetricType.ElectricityL2ActivePower]: Metric;
  [MetricType.ElectricityL3ActivePower]: Metric;
  [MetricType.ElectricityTotalActivePower]: Metric;
  [MetricType.ElectricityMaxTotalPowerDemand]: Metric;
  [MetricType.ElectricityMaxL1CurrentDemand]: Metric;
  [MetricType.ElectricityMaxL2CurrentDemand]: Metric;
  [MetricType.ElectricityMaxL3CurrentDemand]: Metric;
  [MetricType.ElectricityMaxNCurrentDemand]: Metric;
  [MetricType.ElectricityTotalKwh]: Metric;
  [MetricType.ElectricityL1ImportKwh]: Metric;
  [MetricType.ElectricityL2ImportKwh]: Metric;
  [MetricType.ElectricityL3ImportKwh]: Metric;
  [MetricType.ElectricityL1ExportKwh]: Metric;
  [MetricType.ElectricityL2ExportKwh]: Metric;
  [MetricType.ElectricityL3ExportKwh]: Metric;
  [MetricType.ElectricityL1TotalKwh]: Metric;
  [MetricType.ElectricityL2TotalKwh]: Metric;
  [MetricType.ElectricityL3TotalKwh]: Metric;
}

type MetricTypeValueWithLabel = {
  metricType: MetricType;
  value: number;
  label: string;
};

const DiagnosticsRenewable = ({ device }: PropTypes) => {
  const globalTheme = useTheme();
  const { t } = useTranslation(['molecules']);

  const metrics = createMetricObject(RequiredMetrics, device.metrics);

  if (!metrics) {
    return <Centered>{t('Diagnostics.DiagnosticDataUnavailable', { ns: 'molecules' })}</Centered>;
  }

  function getChartColumns(columns: string[], withTotalColumn: boolean) {
    const chartColumns: ColumnsType<IDiagnostics> = [
      {
        dataIndex: 'name',
        key: 'name',
        render(text: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
          return {
            props: {
              style: {
                background: 'white',
                fontWeight: '500',
                fontSize: 14,
                paddingLeft: 20,
                color: globalTheme.palette.text.fair,
              },
            },
            children: <div>{text}</div>,
          };
        },
        width: 300,
      },
    ];

    columns.forEach((column) => {
      chartColumns.push({
        title: column,
        dataIndex: column,
        key: column,
        width: 175,
        align: 'right' as const,
      });
    });

    if (withTotalColumn) {
      chartColumns.push({
        title: t('Diagnostics.Total', { ns: 'molecules' }),
        dataIndex: 'Total',
        key: 'Total',
        width: 175,
        align: 'right' as const,
      });
    }

    return chartColumns;
  }

  const metricsDataSource: IDiagnostics[] = [
    {
      key: 4,
      name: t('Diagnostics.Voltage', { ns: 'molecules' }),
      L1: stringToFloat(metrics.ElectricityL1Voltage.value, 2),
      L2: stringToFloat(metrics.ElectricityL2Voltage.value, 2),
      L3: stringToFloat(metrics.ElectricityL3Voltage.value, 2),
    },
    {
      key: 5,
      name: t('Diagnostics.Current', { ns: 'molecules' }),
      L1: stringToFloat(metrics.ElectricityL1Current.value, 2),
      L2: stringToFloat(metrics.ElectricityL2Current.value, 2),
      L3: stringToFloat(metrics.ElectricityL3Current.value, 2),
    },
    {
      key: 6,
      name: t('Diagnostics.PowerFactor', { ns: 'molecules' }),
      L1: stringToFloat(metrics.ElectricityL1PowerFactor.value, 2),
      L2: stringToFloat(metrics.ElectricityL2PowerFactor.value, 2),
      L3: stringToFloat(metrics.ElectricityL3PowerFactor.value, 2),
    },
  ];

  const currentDemandDataSource: IDiagnostics[] = [
    {
      key: 1,
      name: t('Diagnostics.MaxCurrent', { ns: 'molecules' }),
      L1: stringToFloat(metrics.ElectricityMaxL1CurrentDemand.value, 2),
      L2: stringToFloat(metrics.ElectricityMaxL2CurrentDemand.value, 2),
      L3: stringToFloat(metrics.ElectricityMaxL3CurrentDemand.value, 2),
      N: stringToFloat(metrics.ElectricityMaxNCurrentDemand.value, 2),
    },
  ];

  const systemPowerDataSource: IDiagnostics[] = [
    {
      key: 1,
      name: t('Diagnostics.MaxTotalPower', { ns: 'molecules' }),
      Total: round(
        stringToFloat(metrics.ElectricityMaxTotalPowerDemand.value, 3) / 1000,
        2
      ),
    },
  ];

  const generatedDataSource: MetricTypeValueWithLabel[] = [
    {
      metricType: MetricType.ElectricityL1ImportKwh,
      value: stringToFloat(metrics.ElectricityL1ImportKwh.value, 2),
      label: t('Diagnostics.L1', { ns: 'molecules' }),
    },
    {
      metricType: MetricType.ElectricityL2ImportKwh,
      value: stringToFloat(metrics.ElectricityL2ImportKwh.value, 2),
      label: t('Diagnostics.L2', { ns: 'molecules' }),
    },
    {
      metricType: MetricType.ElectricityL3ImportKwh,
      value: stringToFloat(metrics.ElectricityL3ImportKwh.value, 2),
      label: t('Diagnostics.L3', { ns: 'molecules' }),
    },
  ];

  const consumedDataSource: MetricTypeValueWithLabel[] = [
    {
      metricType: MetricType.ElectricityL1ExportKwh,
      value: stringToFloat(metrics.ElectricityL1ExportKwh.value, 2),
      label: t('Diagnostics.L1', { ns: 'molecules' }),
    },
    {
      metricType: MetricType.ElectricityL2ExportKwh,
      value: stringToFloat(metrics.ElectricityL2ExportKwh.value, 2),
      label: t('Diagnostics.L2', { ns: 'molecules' }),
    },
    {
      metricType: MetricType.ElectricityL3ExportKwh,
      value: stringToFloat(metrics.ElectricityL3ExportKwh.value, 2),
      label: t('Diagnostics.L3', { ns: 'molecules' }),
    },
  ];

  const activePowerDataSource: MetricTypeValueWithLabel[] = [
    {
      metricType: MetricType.ElectricityL1ActivePower,
      value: round(
        stringToFloat(metrics.ElectricityL1ActivePower.value, 3) / 1000,
        2
      ),
      label: t('Diagnostics.L1', { ns: 'molecules' }),
    },
    {
      metricType: MetricType.ElectricityL2ActivePower,
      value: round(
        stringToFloat(metrics.ElectricityL2ActivePower.value, 3) / 1000,
        2
      ),
      label: t('Diagnostics.L2', { ns: 'molecules' }),
    },
    {
      metricType: MetricType.ElectricityL3ActivePower,
      value: round(
        stringToFloat(metrics.ElectricityL3ActivePower.value, 3) / 1000,
        2
      ),
      label: t('Diagnostics.L3', { ns: 'molecules' }),
    },
  ];

  return (
    <>
      <FlexRow>
        <Wrapper>
          <DiagnosticGraphWidget
            cardTitle={t('Diagnostics.ElectricityGenerated', { ns: 'molecules' })}
            data={generatedDataSource}
            legendValueSuffix="&nbsp;kWh"
            tooltipHeading={t('Diagnostics.ElectricityGeneratedWidget', { ns: 'molecules' })}
            tooltipText={
              <>
                <p>{t('Diagnostics.ElectricityGeneratedTooltip', { ns: 'molecules' })}</p>
              </>
            }
            displayTotal
            total={stringToFloat(metrics.ElectricityGeneratedKwh.value, 2)}
          />
        </Wrapper>
        <Wrapper>
          <DiagnosticGraphWidget
            cardTitle={t('Diagnostics.ElectricityConsumed', { ns: 'molecules' })}
            data={consumedDataSource}
            legendValueSuffix="&nbsp;kWh"
            tooltipHeading={t('Diagnostics.ElectricityConsumedWidget', { ns: 'molecules' })}
            tooltipText={
              <>
                <p>{t('Diagnostics.ElectricityConsumedTooltip', { ns: 'molecules' })}</p>
              </>
            }
            displayTotal
            total={stringToFloat(metrics.ElectricityKwh.value, 2)}
          />
        </Wrapper>
        <Wrapper>
          <DiagnosticGraphWidget
            cardTitle={t('Diagnostics.ActivePower', { ns: 'molecules' })}
            data={activePowerDataSource}
            legendValueSuffix="&nbsp;kW"
            tooltipHeading={t('Diagnostics.ActivePowerWidget', { ns: 'molecules' })}
            tooltipText={
              <>
                <p>{t('Diagnostics.ActivePowerTooltip', { ns: 'molecules' })}</p>
              </>
            }
            displayTotal
            total={round(
              stringToFloat(metrics.ElectricityTotalActivePower.value, 3) /
              1000,
              2
            )}
          />
        </Wrapper>
      </FlexRow>
      <TableWrapper>
        <Table<IDiagnostics>
          className="ant-table"
          bordered
          dataSource={metricsDataSource}
          columns={getChartColumns(['L1', 'L2', 'L3'], false)}
          pagination={false}
        />
      </TableWrapper>
      <TableWrapper>
        <Table<IDiagnostics>
          className="ant-table"
          bordered
          dataSource={currentDemandDataSource}
          columns={getChartColumns(['L1', 'L2', 'L3', 'N'], false)}
          pagination={false}
        />
      </TableWrapper>
      <TableWrapper>
        <Table<IDiagnostics>
          className="ant-table"
          bordered
          dataSource={systemPowerDataSource}
          columns={getChartColumns([], true)}
          pagination={false}
        />
      </TableWrapper>
    </>
  );
};

const Wrapper = styled.div<{ width?: string }>`
  margin-bottom: 15px;
  box-shadow: 0 0 8px 0px ${(p) => p.theme.palette.shadows.medium};

  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;

  /*
   * Place three divs next to each other on larger screens.
   */
  @media (min-width: 1300px) {
    flex: 0 0 calc((100% - 40px) / 3);
    max-width: calc((100% - 40px) / 3);
  }
`;

const TableWrapper = styled.div<{ width?: string }>`
  margin-bottom: 15px;
  box-shadow: 0 0 8px 0px ${(p) => p.theme.palette.shadows.medium};
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
`;

const Centered = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 400;
  color: ${(p) => p.theme.palette.text.weak};
`;

export default DiagnosticsRenewable;
