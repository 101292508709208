import styled from 'styled-components';
import DiagnosticChart from './DiagnosticChart';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { MetricType } from '@api/enums/MetricType';
import { Card } from '@components/core/Card';
import MonoSpace from '@components/core/MonoSpace';
import { MetricType_ChartColor } from '@api/enums/MetricType_ChartColor';

export type MetricTypeValueWithLabel = {
  metricType: MetricType;
  value: number;
  label: string
};

type PropTypes = {
  cardTitle: string;
  data: MetricTypeValueWithLabel[];
  legendValueSuffix: string;
  tooltipHeading?: string;
  tooltipText?: ReactNode;
  displayTotal?: boolean;
  total?: number;
  colorsOverride?: string[];
};

const DiagnosticGraphWidget = ({
  cardTitle,
  data,
  legendValueSuffix,
  tooltipHeading,
  tooltipText,
  displayTotal,
  total,
  colorsOverride,
}: PropTypes) => {
  const { t } = useTranslation(['molecules']);

  if (!data.some((x) => x.value > 0)) {
    return null;
  }

  return (
    <Card
      cardTitle={cardTitle}
      autoWidth
      modalTitle={tooltipHeading}
      modalContent={tooltipText}
      noMargin
    >
      <DiagnosticChart
        datasets={[
          {
            dataUnit: legendValueSuffix,
            label: cardTitle,
            dataset: data.map((x) => x.value),
          },
        ]}
        labels={data.map((x) => x.label)}
        colors={colorsOverride ?? data.map((x) => MetricType_ChartColor(x.metricType))}
      />
      <Divider />
      <Legend>
        {data.map((x, i) => (
          <LegendRow key={i}>
            <LegendColorSquare color={colorsOverride ? colorsOverride[i] : MetricType_ChartColor(x.metricType)} />
            <LegendMetricLabel>{x.label}</LegendMetricLabel>
            <LegendValue>
              <MonoSpace>{x.value}</MonoSpace>
              {legendValueSuffix}
            </LegendValue>
          </LegendRow>
        ))}

        {displayTotal && (
          <>
            <Divider />
            <LegendRow>
              <LegendTotalLabel>{t('Diagnostics.Total', { ns: 'molecules' })}</LegendTotalLabel>
              <TotalValue>
                <MonoSpace>{total}</MonoSpace>
                {legendValueSuffix}
              </TotalValue>
            </LegendRow>
          </>
        )}
      </Legend>
    </Card>
  );
};

export default DiagnosticGraphWidget;

const Legend = styled.div`
  margin-top: 10px;
`;

const LegendRow = styled.div`
  display: flex;
  align-items: center;
`;

const Divider = styled.hr`
  width: 100%;
  border: none;
  border-top: 1px solid ${(p) => p.theme.palette.borders.weak};
  margin-bottom: 20px;
`;

const LegendColorSquare = styled.div<{ color: string }>`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  border-radius: 2px;
  background-color: ${(p) => p.color};
`;

const LegendMetricLabel = styled.div`
  font-size: 16px;
  color: ${p => p.theme.palette.text.weak};
`;

const LegendTotalLabel = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${(p) => p.theme.palette.primary};
`;

const LegendValue = styled.div`
  margin-left: auto;
  font-size: 16px;
  font-weight: 500;
`;

const TotalValue = styled.div`
  margin-left: auto;
  font-size: 16px;
  font-weight: 500;
  color: ${(p) => p.theme.palette.primary};
`;
