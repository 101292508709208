import { Table } from '@components/table/Table';
import { useTranslation } from 'react-i18next';
import { ITableColumn, TableFilter } from '@components/table/Table.types';
import { useMemo } from 'react';
import { useDeviceConfigContext } from '@contexts/DeviceConfigContext/DeviceConfigContext';
import { Popover } from '@components/core/Popover';
import { usePopover } from '@hooks/usePopover';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled, { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PopoverPlacement } from '@components/core/Popover.types';
import { Button } from '@components/core/Button';
import PopoverActionMenu, { ActionItem } from '@components/core/PopoverActionMenu';
import { useNavigate } from 'react-router-dom';
import { format, formatDistance } from 'date-fns';
import { GetMetricValue } from '@utils/MetricUtils';
import { MetricType } from '@api/enums/MetricType';
import { DeviceDetails } from '@api/models/DeviceDetails';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { useDeviceManagement } from '@hooks/useDeviceManagement';

type DeviceManagementTableProps = {
  devices: DeviceDetails[];
  setTableRecordCount: (count: number) => void;
  searchString: string;
  loading?: boolean;
  filters?: TableFilter<DeviceDetails>[];
  onRowClick?: (row: DeviceDetails) => void;
  showActionMenu?: boolean;
}

const isGatewayDevice = (device: string) => device.includes('Kerlink');

// action menu for each row
const PopoverMenu = ({ device }: { device: DeviceDetails }) => {
  const { visible, toggle, ref } = usePopover({});
  const theme = useTheme();
  const { t } = useTranslation('settingsDevice');
  const navigate = useNavigate();

  const actions: ActionItem[] = [
    {
      id: 'editFriendlyName',
      label: t('DeviceEditing.Edit'),
      onClick: () => isGatewayDevice(device.deviceModel) ? navigate(`gateway/${device.deviceId}`) : navigate(`${device.deviceId}/edit`),
    },
    ...isGatewayDevice(device.deviceModel) ? [] : [
      { id: 'move', label: t('DeviceEditing.Move'), onClick: () => navigate(`${device.deviceId}/move`) },
      { id: 'replace', label: t('DeviceEditing.Replace'), onClick: () => navigate(`${device.deviceId}/replace`) },
      { id: 'merge', label: t('DeviceEditing.Merge'), onClick: () => navigate(`${device.deviceId}/merge`) },
    ]
  ];

  return (
    <Popover
      ref={ref}
      visible={visible}
      popoverContent={<PopoverActionMenu actionItems={actions} />}
      placement={PopoverPlacement.BottomRight}
      hideTriangle={true}
      offsetY={-8}
      offsetX={-45}
      containerStyles={{
        boxShadow: `0px 1px 3px 1px ${theme.palette.shadows.extraLight}`,
      }}
    >
      <Button
        label={<MenuIcon icon={solid('ellipsis-v')} open={visible} />}
        onClick={toggle}
        tertiary
        style={{ width: '15px', height: '15px', borderRadius: '50%', padding: '10px' }}
      />
    </Popover>
  )
}

const DeviceManagementTable = ({ devices, setTableRecordCount, searchString, loading, filters, onRowClick, showActionMenu }: DeviceManagementTableProps) => {
  const { t } = useTranslation('settingsDevice');
  const { getDisplayString } = useDeviceConfigContext();
  const { localisation } = useLocalisation();
  const { getLastReading } = useDeviceManagement();

  const tableColumns: ITableColumn<DeviceDetails>[] = useMemo(() => ([
    {
      label: t('DeviceManagement.FriendlyName'),
      key: 'friendlyName',
      width: 2
    },
    {
      label: t('DeviceManagement.DeviceModel'),
      key: 'deviceModel',
      displayFormat: ({ deviceModel }) => isGatewayDevice(deviceModel) ? deviceModel : getDisplayString(deviceModel),
      width: 2
    },
    {
      label: t('DeviceManagement.DeviceIdentifier'),
      key: 'deviceIdentifier',
      width: 2
    },
    {
      label: t('DeviceManagement.Space'),
      key: 'spaceName',
    },
    {
      label: t('DeviceManagement.LastMeasuredOn'),
      key: 'lastMeasuredOn',
      sortFormat: device => device.lastMeasuredOn && new Date(device.lastMeasuredOn),
      displayFormat: device => device.lastMeasuredOn ? format(new Date(device.lastMeasuredOn), localisation.dateFormats.default) : 'Never',
      displaySuffix: device => device.lastMeasuredOn ? `\n(${formatDistance(new Date(device.lastMeasuredOn), new Date(), { addSuffix: true })})` : '',
    },
    {
      label: t('DeviceManagement.LastMeterReading'),
      key: 'metrics',
      displayFormat: device => getLastReading(device.deviceModel, device.metrics).Message,
      displaySuffix: device => getLastReading(device.deviceModel, device.metrics).MetricTypeSuffix,
      rightAlign: true,
      disableSort: true,
    },
    {
      label: t('DeviceManagement.Power'),
      key: 'power',
      width: 1,
      sortFormat: (device: DeviceDetails) => {
        const value = device.metrics ? GetMetricValue({ metrics: device.metrics }, MetricType.BatteryVoltage) : 0;
        return value && parseFloat(value);
      },
      displayFormat: (device: DeviceDetails) => {
        const value = device.metrics ? GetMetricValue({ metrics: device.metrics }, MetricType.BatteryVoltage) : 0;
        return value ? `${parseFloat(value).toFixed(2)}v` : '-';
      },
    },
    {
      label: t('DeviceManagement.Signal'),
      key: 'signal',
      width: 1,
      sortFormat: (device: DeviceDetails) => {
        const value = GetMetricValue(device, MetricType.SignalStrength);
        return value && parseFloat(value);
      },
      displayFormat: (device: DeviceDetails) => {
        const value = GetMetricValue(device, MetricType.SignalStrength);
        return value ? `${parseFloat(value).toFixed(0)}%` : '-'
      },
    },
    // add custom element if action items
    ...(showActionMenu ? [{
      key: 'actions',
      fixedWidth: '60px',
      customElement: (device: DeviceDetails) => <PopoverMenu device={device as DeviceDetails} />
    }] : [])
  ]), [getDisplayString, getLastReading, localisation, showActionMenu, t]);

  return (
    <Table
      columns={tableColumns}
      records={devices}
      recordKey="id"
      emptyMessage={t('DeviceManagement.NoDevicesFound')}
      defaultSortColumn="friendlyName"
      onRowClick={onRowClick ? (row: DeviceDetails) => onRowClick(row) : undefined}
      loading={loading}
      cardEffect
      highlightString={searchString}
      filters={filters}
      onRecordCountChange={setTableRecordCount}
      minHeight={'100%'}
      showOverflow={true}
      recordBorder={true}
      fitContent={true}
    />
  )
}

export default DeviceManagementTable;

const MenuIcon = styled(FontAwesomeIcon) <{ open: boolean }>`
  color: ${p => p.open ? p.theme.primary.main : p.theme.action.active};
  cursor: pointer;
`;