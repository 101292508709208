import { useTheme } from 'styled-components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import { constructChartOptions } from './chartOptions';
import { useTranslation } from 'react-i18next';
import { TimeRange, TimeRangeType } from '@components/time-range-select/TimeRangeSelect';
import { SiteUtilityDailyConsumptionDto } from '@api/models/SiteUtilityDailyConsumptionDto';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { accumulateDataPoints, ConsumptionChartDataset, ConsumptionChartStack, CustomUtilityCategory, generateCsv, generateDataPoints, generateLabels, getChartType, getDatasetProperties, sortUtilities, TChart, TConversion } from './utilityChartUtils';
import { ConsumptionChartView } from './ChartViewToggle';
import { TUtilityTab } from '../SiteUtilitiesWidget';
import dayjs from 'dayjs';

type PropTypes = {
  utility: TUtilityTab;
  data?: SiteUtilityDailyConsumptionDto;
  timeRangeType: TimeRangeType;
  timeRange: TimeRange;
  view: ConsumptionChartView;
  applyConversion: boolean;
  onCsvGenerated: (csv: string, fileName: string) => void;
};

const useUtilityChart = ({ utility, data, timeRangeType, timeRange, view, applyConversion, onCsvGenerated }: PropTypes) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { localisation } = useLocalisation();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [chart, setChart] = useState<TChart>();
  const [labels, setLabels] = useState<string[]>();
  const [datasets, setDatasets] = useState<ConsumptionChartDataset[]>();
  const [filteredDatasets, setFilteredDatasets] = useState<ConsumptionChartDataset[]>();
  const [hiddenDatasets, setHiddenDatasets] = useState<{ [key: string]: string }>({});
  const isSmallRange = useMemo(() => timeRange.to.diff(timeRange.from, 'day') < 14, [timeRange]);
  const aggregateData = useMemo(() => timeRange.to.diff(timeRange.from, 'day') > 90, [timeRange]);
  const conversion: TConversion = useMemo(() => ({
    unit: applyConversion ? utility.conversion?.unit ?? utility.unit : utility.unit,
    modifier: applyConversion ? utility.conversion?.modifier : utility.modifier
  }), [utility, applyConversion]);

  const generateDatasets = useCallback((labels: string[]): ConsumptionChartDataset[] => {
    if (!data) {
      return [];
    }

    const currentYearDatasets: ConsumptionChartDataset[] = sortUtilities(data.currentYearPeriodCategories).map((category, i) => {
      const chartType = getChartType(category.category);
      const isTarget = category.category === CustomUtilityCategory.Target;

      return {
        id: `currentYear-${i}`,
        category: category.category,
        label: t(category.category, { ns: 'enums' }),
        dataUnit: conversion.unit,
        data: generateDataPoints(labels, category, conversion, aggregateData),
        type: chartType,
        stack: isTarget ? ConsumptionChartStack.Target : ConsumptionChartStack.ThisYear,
        order: isTarget ? 0 : 1,
        ...getDatasetProperties(category.category, chartType, theme, ConsumptionChartStack.ThisYear, i)
      };
    });

    const previousYearDatasets: ConsumptionChartDataset[] = sortUtilities(data.previousYearPeriodCategories).map((category, i) => {
      const chartType = getChartType(category.category);

      return {
        id: `previousYear-${i}`,
        category: category.category,
        label: t(category.category, { ns: 'enums' }),
        dataUnit: conversion.unit,
        data: generateDataPoints(labels, category, conversion, aggregateData, -1),
        type: chartType,
        stack: ConsumptionChartStack.LastYear,
        order: 1,
        ...getDatasetProperties(category.category, chartType, theme, ConsumptionChartStack.LastYear, i),
      };
    });

    return [...previousYearDatasets, ...currentYearDatasets];
  }, [data, theme, conversion, aggregateData, t]);

  // Generate labels and datasets
  useEffect(() => {
    const labels = generateLabels(timeRange);
    const datasets = generateDatasets(labels);

    setDatasets(datasets);
    setLabels(labels);
    onCsvGenerated(generateCsv(datasets, t, localisation, conversion.unit), `${utility.meterType}_${timeRange.from.format(localisation.dateFormats.date)}-${timeRange.to.format(localisation.dateFormats.date)}`);
  }, [generateDatasets, onCsvGenerated, t, conversion, timeRange, utility, localisation]);

  /**
   * Filter datasets based on selected view type
   */
  useEffect(() => {
    if (!datasets) {
      return;
    }

    let filteredDatasets = [...datasets];

    if (view === ConsumptionChartView.Periodical) {
      filteredDatasets = filteredDatasets.filter(x => x.category !== CustomUtilityCategory.DailyTotal);
    } else {
      const now = dayjs();
      // Only display DailyTotal and Target datasets
      filteredDatasets = filteredDatasets.filter(x => x.category === CustomUtilityCategory.DailyTotal || x.category === CustomUtilityCategory.Target);
      // Accumulate data points and remove any data points in the future apart from the target dataset
      // This is to prevent future months to show on the chart with the same accumulated data as the current month
      filteredDatasets = filteredDatasets.map(dataset => ({
        ...dataset, data: accumulateDataPoints(dataset.data)
          .filter(x => dataset.category === CustomUtilityCategory.Target || x.date < now)
      }))
    }

    setFilteredDatasets(filteredDatasets);
  }, [datasets, view]);

  /**
   * Create the chart component and attach it to the canvas element (referenced by the ref 'canvasRef').
   */
  useEffect(() => {
    const context = canvasRef.current?.getContext('2d');

    const xAxisMin = labels?.[0];
    const xAxisMax = labels?.[labels.length - 1];

    if (context && labels && filteredDatasets) {
      const chart = new Chart(context, {
        type: 'bar',
        data: { labels: labels, datasets: filteredDatasets },
        options: constructChartOptions(theme, localisation, t, timeRangeType, isSmallRange, aggregateData, view, xAxisMin, xAxisMax)
      });

      setChart(chart);
      return () => chart.destroy();
    }
  }, [filteredDatasets, labels, theme, localisation, isSmallRange, aggregateData, view, timeRangeType, t]);

  /**
   * Modify the object containing keys (the dataset id) of the hidden datasets as they are selected/deselected in the legend.
   */
  const toggleDataset = useCallback((id: string) => {
    const hidden = { ...hiddenDatasets };
    const arrayIndex = hidden[id];

    if (arrayIndex) {
      delete hidden[id];
    } else {
      hidden[id] = id;
    }

    setHiddenDatasets(hidden);
  }, [hiddenDatasets]);

  /**
   * Show/hide datasets from the chart as they are selected/deselected in the legend component.
   */
  useEffect(() => {
    if (chart) {
      chart.data.datasets.forEach((dataset) => {
        dataset.hidden = !!hiddenDatasets[(dataset as unknown as ConsumptionChartDataset).id];
      });

      try {
        chart.update();
      } catch (error) {
        return;
      }
    }
  }, [chart, hiddenDatasets]);

  return {
    canvasRef,
    datasets: filteredDatasets,
    hiddenDatasets,
    toggleDataset
  };
};

export default useUtilityChart;