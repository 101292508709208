import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DiagnosticsDefault from './DiagnosticsDefault';
import DiagnosticsRenewable from './DiagnosticsRenewable';
import { useDeviceConfigContext } from '@contexts/DeviceConfigContext/DeviceConfigContext';
import { DeviceWithMetrics } from '@api/models/DeviceWithMetrics';
import { Title } from '@components/core/Title';
import CsvMeasurementsGetByDeviceQuery from '@api/queries/exports/CsvMeasurementsGetByDeviceQuery';
import useCsvExport from '@hooks/useCsvExport';
import ButtonWithRangePicker from '@components/core/ButtonWithRangePicker';
import { TimeRange } from '@components/core/RangePicker';

type PropTypes = {
  device: DeviceWithMetrics;
};

const Diagnostics = ({ device }: PropTypes) => {
  const { getDeviceConfig } = useDeviceConfigContext();
  const deviceConfig = getDeviceConfig(device.deviceModel);
  const { t } = useTranslation(['molecules']);
  const { handleCsvExport: exportMeasurements, loading: loadingMeasurementsExport } = useCsvExport();

  const handleExport = async (timeRange: TimeRange) => {
    const query = new CsvMeasurementsGetByDeviceQuery(device.deviceIdentifier, timeRange.from, timeRange.to);
    const fileName = `${device.deviceIdentifier}_${t('Diagnostics.Measurements', { ns: 'molecules' })}.csv`
    exportMeasurements(query, fileName);
  };

  if (!deviceConfig) {
    return <Centered>{t('DeviceCard.DeviceNotSupported', { ns: 'molecules' })}</Centered>;
  }

  if (!deviceConfig.ui.diagnosticsPage) {
    return null;
  }

  return (
    <>
      <TitleRow>
        <Title text={t('Diagnostics.Diagnostics', { ns: 'molecules' })} wrapperStyle={{ paddingBottom: '20px' }} />

        <RightAligned>
          <ButtonWithRangePicker
            label={t('ExportCSV', { ns: 'common' })}
            loading={loadingMeasurementsExport}
            onRangeChange={handleExport}
            maxDays={31} />
        </RightAligned>
      </TitleRow>

      {!deviceConfig.ui.diagnosticsPage.renewableMeter &&
        <DiagnosticsDefault device={device} />
      }

      {deviceConfig.ui.diagnosticsPage.renewableMeter &&
        <DiagnosticsRenewable device={device} />
      }
    </>
  );
};

export default Diagnostics;

const Centered = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 400;
  color: ${p => p.theme.palette.text.weak};
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
`;

const RightAligned = styled.div`
  margin-left: auto;
`;
